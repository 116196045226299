import { Injectable } from '@angular/core';
import sounds from './../../assets/content/sounds.json';

export enum NotificationType {}

export const SoundTypes = Object.freeze({
  notification: 'notification',
  chat: 'chat',
  error: 'error',
});

export interface SoundFile {
  path?: string;
}

const MIN_SOUND_INTERVAL_MILLISECONDS = 2000;
@Injectable({ providedIn: 'root' })
export class SoundService {
  soundList: Map<string, SoundFile>;
  audio: HTMLAudioElement;
  lastSoundPlayedTime: Date;
  constructor() {
    this.loadSounds();
    this.audio = new Audio();
  }

  loadSounds() {
    this.soundList = new Map(
      sounds.map<[string, SoundFile]>(
        (soundItem: Dictionary<string, SoundFile>) => [
          soundItem.key,
          soundItem.value,
        ],
      ),
    );
  }

  playSound(soundType: string) {
    const currentTime = new Date();
    // Play only one time when there is multiple request at a same time. For example,
    // play only once for multiple messages when the WIFI connection is back.
    if (
      !this.lastSoundPlayedTime ||
      currentTime.getTime() - this.lastSoundPlayedTime.getTime() >
        MIN_SOUND_INTERVAL_MILLISECONDS
    ) {
      const path = this.soundList.get(soundType).path;
      this.audio.src = path;
      const playedPromise = this.audio.play();
      this.lastSoundPlayedTime = new Date();
      if (playedPromise) {
        playedPromise.catch((e) => {
          console.log(e);
        });
      }
    }
  }
}
export interface SoundFile {
  path?: string;
}

export interface Dictionary<T, V> {
  key: T;
  value: V;
}
