<div class="arp-sicom-display-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row row-gap">
        <div class="col-md-5 col-sm-12">
          <div class="row mb-1">
            <div class="col-xl-4 col-5">
              <ng-container
                *ngIf="marketSelectField$ | async as marketSelectField">
                <mat-form-field
                  class="arp-mat-form-field-outline full-width"
                  appearance="outline">
                  <mat-label>{{ marketSelectField.label }}</mat-label>
                  <mat-select
                    [placeholder]="marketSelectField.placeholder"
                    [value]="marketSelectField.selectedOptions"
                    (valueChange)="onSelectMarket($event)">
                    <mat-option
                      *ngFor="let option of marketSelectField.options"
                      [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
            <div class="col-xl-4 col-5">
              <ng-container
                *ngIf="monthSelectField$ | async as monthSelectField">
                <mat-form-field
                  class="arp-mat-form-field-outline full-width"
                  appearance="outline">
                  <mat-label>{{ monthSelectField.label }}</mat-label>
                  <mat-select
                    [placeholder]="monthSelectField.placeholder"
                    [value]="monthSelectField.selectedOptions"
                    (valueChange)="onSelectMonth($event)">
                    <mat-option
                      *ngFor="let option of monthSelectField.options"
                      [value]="option">
                      {{ option | date: monthFormat }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row row-gap align-center">
            <ng-container *ngIf="selectedSicom$ | async as selectedSicom">
              <div class="col-12">
                <div class="expanded-display">
                  <span class="normal-font mr-1">SICOM Prices:</span>
                  <ng-container *ngIf="selectedSicom.currentPrice; else empty">
                    <span class="extra-large-font emphasized text-blue mr-1">
                      {{ selectedSicom.currentPrice | number: "1.2-2" }}
                    </span>
                    <mat-icon
                      class="mr-1"
                      [class]="selectedSicom.changeDirection">
                      {{ selectedSicom.changeIcon }}
                    </mat-icon>
                    <span
                      class="normal-font"
                      [class]="selectedSicom.changeDirection">
                      {{ selectedSicom.change | number: "1.2-2" }}/{{
                        selectedSicom.changePercent | number: "1.2-2"
                      }}%
                    </span>
                  </ng-container>
                  <ng-template #empty>
                    <span class="extra-large-font emphasized text-blue mr-1">
                      -
                    </span>
                  </ng-template>
                </div>
                <ng-container *ngIf="lastUpdatedTimeString$ | async as time">
                  <ng-container
                    *ngIf="sixStreamStatus$ | async as streamStatus">
                    <div class="align-center">
                      <mat-icon
                        class="mr-1"
                        [class.not-display]="
                          streamStatus.state === 'Live' ||
                          streamStatus.state === undefined
                        "
                        [class.delayed-color]="streamStatus.state === 'Delayed'"
                        [class.stale-color]="streamStatus.state === 'Stale'"
                        [matTooltip]="streamStatus.toolTip | transloco"
                        matTooltipClass="custom-mat-tooltip px-2">
                        warning
                      </mat-icon>
                      <span>Last updated: {{ time }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">High</div>
                <div class="field-value">
                  {{ selectedSicom.currentHighPrice | number: "1.2-2" }} USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Low</div>
                <div class="field-value">
                  {{ selectedSicom.currentLowPrice | number: "1.2-2" }} USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Open</div>
                <div class="field-value">
                  {{ selectedSicom.currentOpenPrice | number: "1.2-2" }} USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Prev Day Settle</div>
                <div class="field-value">
                  {{ selectedSicom.prevDaySettlementPrice | number: "1.2-2" }}
                  USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Open Interest</div>
                <div class="field-value">
                  {{ selectedSicom.currentOpenInterest }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Bid Price</div>
                <div class="field-value">
                  {{ selectedSicom.currentBidPrice | number: "1.2-2" }} USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Bid Size</div>
                <div class="field-value">
                  {{ selectedSicom.currentBidSize }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Offer Price</div>
                <div class="field-value">
                  {{ selectedSicom.currentOfferPrice | number: "1.2-2" }} USC/kg
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Offer Size</div>
                <div class="field-value">
                  {{ selectedSicom.currentOfferSize }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-6">
                <div class="field-name">Volume</div>
                <div class="field-value">{{ selectedSicom.currentVolume }}</div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 chart-container">
          <ng-container *ngIf="chartLoading$ | async">
            <div class="local-loader">
              <app-arp-loader></app-arp-loader>
            </div>
          </ng-container>
          <div class="range-picker">
            <ng-container *ngIf="rangeToggleField$ | async as rangeToggleField">
              <mat-button-toggle-group
                class="arp-mat-button-toggle-group"
                [name]="rangeToggleField.name"
                aria-label="range"
                [appearance]="rangeToggleField.appearance"
                [value]="rangeToggleField.selectedOptions"
                (change)="onSelectRange($event)">
                <mat-button-toggle
                  *ngFor="let option of rangeToggleField.options"
                  [value]="option"
                  >{{ option }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
          </div>
          <div class="chart-area">
            <div class="chart" arpLightweightChart></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
