import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { HttpLoaderService } from 'src/app/services/http-loader.service';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './services/mixpanel.service';
import { OpenTabsService } from './services/open-tabs.service';
import { Theme, ThemeService } from './services/theme.service';
declare function gtag(
  command: string,
  targetId: string,
  additionalConfiguration: object,
);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  /**
   * Boolean controlling whether the loading icon is shown/hidden
   */
  loading: boolean;
  constructor(
    private tabService: OpenTabsService,
    private router: Router,
    private httpLoaderService: HttpLoaderService,
    private mixpanelService: MixpanelService,
    private themeService: ThemeService,
    private renderer2: Renderer2,
  ) {}

  /**
   * Host Listener for tab close event.
   * Use the OpenTabsService to manage list of
   * opened tabs.
   * @todo consider removing the tab
   * management feature.
   * @param event the window:pagehide event
   */
  @HostListener('window:pagehide', ['$event'])
  closeTab(event: PageTransitionEvent) {
    if (!event.persisted) {
      this.tabService.deleteTab();
    }
  }

  /**
   * On Initialization:
   * 1. Listen for "loading" event to show/hide loading icon
   * 2. Show Build Information in console
   * 3. Subscribe to router's NavigationEnd event for g-tagging
   * 4. Initialize the Mixpanel Service
   * @todo consider moving Mixpanel Initialization to APP_INITIALIZER
   */
  ngOnInit() {
    this.themeService.loadTheme();
    this.themeService.theme$.subscribe((theme) => {
      this.applyThemeStyles(theme.style.colors);
    });

    this.listenToLoading();
    this.showBuildInformation();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (typeof gtag !== 'undefined') {
          gtag('config', environment.GOOGLE_TRACKING_ID, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
    this.mixpanelService.init();
  }

  applyThemeStyles(style: Theme['style']['colors']) {
    const cssVariables = Object.keys(style)
      .map((property: keyof Theme) => `--${property}: ${style[property]};`)
      .join(' ');

    this.renderer2.setAttribute(
      document.documentElement,
      'style',
      cssVariables,
    );
  }

  /**
   * Subscribe to HttpLoaderService.loadingSub to show/hide loading icon
   */
  listenToLoading(): void {
    this.httpLoaderService.loadingSub.pipe(delay(0)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  /**
   * Show Build Information in console.
   * Mainly used to determine if newer app version is deployed.
   */
  showBuildInformation() {
    const currEnvironment = { name: 'Environment', value: environment.name };
    //currently build number and app version are same and updated manually in package.json
    //version:XX.XX.XX(Major.Minor.Patch)
    const buildNumber = { name: 'Build Number', value: environment.appVersion };
    const productVersion = {
      name: 'Product Version',
      value: environment.appVersion,
    };
    const buildAt = { name: 'Build At', value: environment.timeStamp };

    console.log(
      `%cBuild Information:\n${currEnvironment.name}:${currEnvironment.value}\n${buildAt.name}:${buildAt.value}\n${buildNumber.name}:${buildNumber.value}\n${productVersion.name}:${productVersion.value}`,
      'background: #fff; color: green',
    );
  }
}
