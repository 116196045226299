import { Injectable } from '@angular/core';
import menuItems from 'src/app/components/main-menu/menu-items.json';
import { ProfileName } from 'src/app/models/profile-name';
import { EudrMenuItems, NavItem } from '../components/main-menu/main-menu';
import { ApprovalType } from '../models/approval';
import { CompanyType } from '../models/company';
import { StorageService } from './storage.service';
import { ApprovalFeature } from '../models/api/responses/company-settings';

@Injectable({
  providedIn: 'root',
})
export class FeaturePermissionService {
  /**
   * For mapping the key from frontend kebab case to backend snake_case
   * This is very subjective and can be changed in the future
   */
  private featurePermissionMap = {
    dashboard: null,
    'price-discovery': null,
    'price-configurator': 'price_configurator',
    spot: 'spot',
    'contract-hub': 'trade_confirmation',
    'new-ltc': 'ltc',
    data: 'data',
    'legal-contract': null,
    sustainability: 'sustainability',
    polygonmapping: 'polygonmapping',
    traceabilityv2: 'traceabilityv2',
    eudrmodules: 'eudrmodules',
  };

  constructor(private storageService: StorageService) {}

  /**
   * Retrieve the user access details, from local storage
   * @returns an object containing the user access details,
   * i.e. companyType, requiredApprovals, userApprovalType, featurePermissions
   */
  private getUserAccessDetails() {
    const profile = this.storageService.retrieve<ProfileName>('profileName');
    const requiredApprovals = this.storageService.retrieve(
      'required_approvals',
    ) as ApprovalFeature[];
    const userApprovalType = profile.approvalType;
    const featurePermissions =
      this.storageService.retrieve('featurePermissions');
    return {
      companyType: profile.type,
      requiredApprovals,
      userApprovalType,
      featurePermissions,
    };
  }

  /**
   * Compute the visible menu items for the side menu
   * based on the user access details and the given menu items array
   * @param userAccessDetails the user access details
   * @param menuItems the array of menu items to be filtered
   * @returns the visible menu items for the side menu
   */
  private getAccessibleMenuItems(userAccessDetails, menuItems): NavItem[] {
    const accessibleMenuItems = menuItems
      .filter((menuItem) => {
        return menuItem.visibleTo.companyType === null
          ? true
          : menuItem.visibleTo.companyType.includes(
              userAccessDetails.companyType,
            );
      })
      .filter((menuItem) => {
        return menuItem.visibleTo.requiredApproval === null
          ? true
          : userAccessDetails.requiredApprovals.includes(
              menuItem.visibleTo.requiredApproval,
            );
      })
      .filter((menuItem) => {
        return menuItem.visibleTo.userApprovalType === null
          ? true
          : menuItem.visibleTo.userApprovalType.includes(
              userAccessDetails.userApprovalType,
            );
      })
      .filter((menuItem) => {
        const featurePermissionKey = menuItem.visibleTo.featurePermissionKey;

        let arrayCanView = false;
        if (Array.isArray(featurePermissionKey)) {
          for (const fp of featurePermissionKey) {
            if (this.canViewPermission(fp)) {
              arrayCanView = true;
              break;
            }
          }

          if (arrayCanView) {
            return arrayCanView;
          }
        } else {
          return this.canViewPermission(featurePermissionKey);
        }
      });
    return accessibleMenuItems;
  }

  canViewPermission(featurePermissionKey: string): boolean {
    if (featurePermissionKey === null) {
      return true;
    } else {
      const featurePermission =
        this.getUserAccessDetails().featurePermissions[featurePermissionKey];
      return featurePermission[`${featurePermissionKey}_can_view`];
    }
  }

  private groupMainMenuItems(menuItems: any[]): NavItem[] {
    const topLevelMenus = menuItems.filter((item) => item.parentId === null);
    return topLevelMenus.map((topLevelMenu) => {
      const directChildren = menuItems
        .filter((item) => item.parentId === topLevelMenu.id)
        .map((item) => ({ ...item, children: [] }));
      return {
        ...topLevelMenu,
        children: directChildren,
        expanded: false,
      };
    });
  }

  /**
   * @returns the main menu items the logged in user can view and access
   * based on their profile
   */
  getMainMenuItems(): NavItem[] {
    const userAccessDetails = this.getUserAccessDetails();

    let menus = menuItems;

    const eudrModulesFeaturePermissionKey =
      this.featurePermissionMap.eudrmodules;
    const hasEudrModulesPermission =
      userAccessDetails.featurePermissions[eudrModulesFeaturePermissionKey];

    if (
      hasEudrModulesPermission &&
      hasEudrModulesPermission[`${eudrModulesFeaturePermissionKey}_can_view`]
    ) {
      menus = EudrMenuItems;
    }

    const accessibleMenuItems = this.getAccessibleMenuItems(
      userAccessDetails,
      menus,
    );
    return this.groupMainMenuItems(accessibleMenuItems);
  }

  /**
   * Check the view permission for the featureUrl the user is trying to access,
   * mainly used as a route guard.
   * @param featureUrl the top level route which correspond to the feature that
   * the logged in user is trying to access.
   * @returns true if the user has view access to the feature, false otherwise.
   */
  getViewPermission(featureUrl: string): boolean {
    const permissionKey = this.featurePermissionMap[featureUrl];
    if (permissionKey === null) {
      return true;
    }
    const viewKey = `${permissionKey}_can_view`;
    const featurePermissions =
      this.storageService.retrieve('featurePermissions');
    return featurePermissions[permissionKey][viewKey];
  }

  /**
   * @returns true if the user belongs to a Producer company, false if the user belongs to a Consumer
   * company
   */
  isProducer() {
    const profile = this.storageService.retrieve<ProfileName>('profileName');
    return profile.type === CompanyType.Producer;
  }

  /**
   * @returns true if the user is the Manager/Super Manager, who can approve/reject approval request;
   * false if the user is Trader who can not approve/reject approval request
   */
  isApprovalManager() {
    const profile = this.storageService.retrieve<ProfileName>('profileName');
    return (
      profile.approvalType === ApprovalType.Manager ||
      profile.approvalType === ApprovalType.SuperManager
    );
  }
}
