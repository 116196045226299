import { TranslocoService } from '@ngneat/transloco';
import { catchError, from, map, of } from 'rxjs';
import { IconService } from './hc-icons/icon.service';
import { defaultTheme, Theme, ThemeService } from './services/theme.service';
import { StorageService } from './services/storage.service';
import { AppStorage } from './utils/storage-constants';
import { Router } from '@angular/router';
import { DomainService } from './services/domain.service';

export function initializeDomainService(
  domainService: DomainService,
  translocoService: TranslocoService,
): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      const chinaDomains = ['agridencecn'];
      const url = window.location.href;
      console.log(url);
      const isChina = chinaDomains.some((domain) => url.includes(domain));

      domainService.isChina = isChina;
      domainService.setIsChina(isChina);

      if (isChina) {
        localStorage?.setItem(
          AppStorage.language,
          localStorage.getItem(AppStorage.language) || 'cn',
        );
        translocoService.setActiveLang('cn');
        domainService.setAppConfiguration();
      }

      resolve(); // Resolve the promise after setting the configuration
    });
}

export function registerCustomIcons(iconService: IconService) {
  return () => {
    iconService.registerIcons();
  };
}

export function preLoadTheme(
  storageService: StorageService,
  themeService: ThemeService,
  translocoService: TranslocoService,
  router: Router,
) {
  return () => {
    const tenantId = storageService.retrieve(AppStorage.tenantId) as string;
    const authorizationFromStorage = storageService.retrieve(
      AppStorage.Authorization,
    );

    if (authorizationFromStorage) {
      router.navigate(['/dashboard']);
      return of(false);
    }

    if (tenantId) {
      storageService.store('tenantId', tenantId);
      return from(themeService.getTheme(tenantId)).pipe(
        map((theme: Theme) => {
          themeService.setTheme({
            ...theme,
          });
          themeService.loadTheme();
          translocoService.setActiveLang(
            localStorage?.getItem(AppStorage.language) ||
              theme?.locale?.defaultLanguage ||
              'engb',
          );
          return true;
        }),
        catchError((error) => {
          console.error('Error fetching theme:', error);
          router.navigate(['/login']);
          return of(false);
        }),
      );
    } else {
      storageService.remove('tenantId');
      themeService.setTheme(defaultTheme);
      themeService.loadTheme();
      translocoService.setActiveLang(
        localStorage?.getItem(AppStorage.language) ||
          defaultTheme?.locale?.defaultLanguage ||
          'engb',
      );
      return of(true);
    }
  };
}
