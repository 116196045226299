import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { combineLatest, map, take } from 'rxjs';
import { ArpLightweightChartDirective } from 'src/app/directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { FileService } from 'src/app/services/file.service';
import {
  PhysicalIndexDataKeyMaps,
  PhysicalIndexLogicService,
} from 'src/app/services/physical-index-logic.service';

@Component({
  selector: 'arp-physical-index',
  templateUrl: './arp-physical-index.component.html',
  styleUrls: ['./arp-physical-index.component.scss'],
  providers: [PhysicalIndexLogicService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArpPhysicalIndexComponent implements OnInit, OnDestroy {
  /**
   * Private reference to lightweight chart directive
   */
  private _chartDirective: ArpLightweightChartDirective;
  /**
   * View Query for the lightweight chart directive,
   * combined with a setter to run assignment operations
   * when the view changes. Assignment operations are guarded
   * by a diff check to prevent unnecessary operations.
   */
  @ViewChild(ArpLightweightChartDirective) set chartDirective(
    chartDirective: ArpLightweightChartDirective,
  ) {
    if (
      this._chartDirective !== chartDirective &&
      chartDirective !== undefined
    ) {
      this._chartDirective = chartDirective;
      this.onChartDirectiveChange(chartDirective);
    }
  }
  /**
   * Retrieve the marketSelectField$ stream from the PhysicalIndexLogicService
   */
  readonly marketSelectField$ = this.logicService.marketSelectField$;
  /**
   * Retrieve the physicalIndex$ stream from the PhysicalIndexLogicService
   */
  readonly physicalIndex$ = this.logicService.physicalIndex$;
  /**
   * Retrieve the sumIndex$ stream from the PhysicalIndexLogicService
   */
  readonly sumIndex$ = this.logicService.sumIndex$;
  /**
   * Retrieve the rangeToggleField$ stream from the PhysicalIndexLogicService
   */
  readonly rangeToggleField$ = this.logicService.rangeToggleField$;
  /**
   * Retrieve the chartLoading$ stream from the PhysicalIndexLogicService
   */
  readonly chartLoading$ = this.logicService.chartLoading$;

  constructor(
    private logicService: PhysicalIndexLogicService,
    private fileService: FileService,
  ) {}
  /**
   * Initialize the states
   */
  ngOnInit(): void {
    this.logicService.initializeStates();
  }

  ngOnDestroy(): void {
    this.logicService.cleanUp();
  }
  /**
   * Handle the market selected by user
   * @param market market name
   */
  onSelectMarket(market: string) {
    this.logicService.selectMarket(market);
  }
  /**
   * Handle the range selected by user
   * @param event MatButtonToggleChange event
   */
  onSelectRange(event: MatButtonToggleChange) {
    this.logicService.selectRange(event.value);
  }
  /**
   * Handle the mat slide toggle event from user
   * @param event MatLegacySlideToggleChange event
   */
  onToggleSumIndex(event: MatLegacySlideToggleChange) {
    this.logicService.toggleSumIndex(event.checked);
  }
  /**
   * Push new chart references that are queried by ViewChild,
   * only if the refs actually change, to the logic service
   * @param chartDirective the lightweight chart directive
   */
  onChartDirectiveChange(chartDirective: ArpLightweightChartDirective) {
    this.logicService.updateChartRefs(chartDirective);
  }

  onDownload() {
    combineLatest([
      this.logicService.selectedRangeData$,
      this.logicService.physicalIndexData$,
    ])
      .pipe(
        take(1),
        map(([selectedRange, data]) => {
          console.log(data);
          this.fileService.downloadCsv(
            data,
            PhysicalIndexDataKeyMaps,
            `physical-index-data-${selectedRange.toLowerCase()}`,
          );
        }),
      )
      .subscribe();
  }
}
