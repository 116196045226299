import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { FeaturePermissionService } from '../services/feature-permission.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featurePermissionService: FeaturePermissionService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const featureUrl: string = route.url[0].path;
    if (this.featurePermissionService.getViewPermission(featureUrl)) {
      return true;
    }
    const redirectUrlTree = this.router.parseUrl('/dashboard');
    return redirectUrlTree;
  }
}
