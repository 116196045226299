<div class="arp-price-widget-container" [class]="widgetWidthClass$ | async">
  <button mat-icon-button color="primary" (click)="moveCarouselLeft(_carousel)">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <div class="carousel-container">
    <ng-container *ngIf="priceWidgets$ | async as priceWidgets">
      <ngu-carousel
        #priceCarousel
        [inputs]="nguCarouselConfig"
        [dataSource]="priceWidgets"
        [trackBy]="trackByTitle">
        <ngu-item *nguCarouselDef="let item">
          <div
            class="widget-display"
            (click)="expandPriceDisplay(item.displayComponent)"
            (keyup.enter)="expandPriceDisplay(item.displayComponent)"
            tabindex="0">
            <div class="align-center">
              <mat-icon
                class="mr-1"
                [class.not-display]="
                  item.streamState === 'Live' || item.streamState === undefined
                "
                [class.delayed-color]="item.streamState === 'Delayed'"
                [class.stale-color]="item.streamState === 'Stale'"
                [matTooltip]="item.toolTip | transloco"
                matTooltipClass="custom-mat-tooltip px-2">
                warning
              </mat-icon>
              <span class="normal-font mr-1">{{ item.title }}:</span>
            </div>
            <div class="align-center">
              <span class="large-font emphasized text-blue mr-1">
                {{ (item.price | number: item.priceFormat) ?? "Coming soon" }}
              </span>
              <span
                class="normal-font"
                [class.not-display]="
                  item.change === null || item.change === undefined
                "
                [class]="item.changeDirection">
                {{ item.change | number: "1.2-2" }}/{{
                  item.changePercent | number: "1.2-2"
                }}%
              </span>
              <span
                class="normal-font"
                [class.not-display]="!item.currencyPair">
                {{ item.currencyPair }}
              </span>
            </div>
          </div>
        </ngu-item>
      </ngu-carousel>
    </ng-container>
  </div>

  <button
    mat-icon-button
    color="primary"
    (click)="moveCarouselRight(_carousel)">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
