export interface NavItem {
  id: number;
  displayName?: string;
  iconName?: string;
  isMatIcon?: boolean;
  route?: string;
  routeQueryParams?: object;
  expanded?: boolean;
  children?: NavItem[];
}

export const EudrMenuItems = [
  {
    id: 0,
    displayName: 'MAIN_MENU.HOME',
    iconName: 'home',
    route: '/dashboard',
    parentId: null,
    visibleTo: {
      companyType: null,
      requiredApproval: null,
      userApprovalType: null,
      featurePermissionKey: null,
    },
  },
  {
    id: 16,
    displayName: 'MAIN_MENU.DATA',
    iconName: 'data',
    route: '/data',
    parentId: null,
    visibleTo: {
      companyType: null,
      requiredApproval: null,
      userApprovalType: null,
      featurePermissionKey: ['eudrmodules', 'data'],
    },
  },
  {
    id: 19,
    displayName: 'MAIN_MENU.TRACEABILITY',
    iconName: '',
    route: '/data/traceability',
    parentId: 16,
    visibleTo: {
      companyType: null,
      requiredApproval: null,
      userApprovalType: null,
      featurePermissionKey: ['eudrmodules', 'data'],
    },
  },
  {
    id: 21,
    displayName: 'MAIN_MENU.POLYGON_MAPPING',
    iconName: '',
    route: '/data/polygon-mapping',
    parentId: 16,
    visibleTo: {
      companyType: null,
      requiredApproval: null,
      userApprovalType: null,
      featurePermissionKey: ['eudrmodules', 'polygonmapping'],
    },
  },
];
